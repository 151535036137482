import { Box, TextField, Typography, Grid, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import Input from '../../shared/forms/input';
import { useJob } from '../../../utils/masterData';
import { useParams } from 'react-router-dom';

export default function JobContactForm({ form, pagePermissions, data, locations }) {
  const { jobId } = useParams();
  const { data: jobContactData } = useJob({ jobId });
  const miscJob = data?.value?.isMiscJob;

  return (
    <Grid>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} gap={4} mt={4}>
        <Grid item xs={12} borderRight={2} pr={2} borderColor={'#f0f0f0'}>
          <Typography variant="h2">Jobsite Address and Contact Information</Typography>
          <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2}>
            <Box display={'flex'} width={'100%'} gap={4}>
              <FormControl size="medium" fullWidth>
                <Controller
                  control={form.control}
                  name="street"
                  render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                    <TextField
                      name={name}
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={'Street'}
                      disabled
                    />
                  )}
                />
              </FormControl>
              <FormControl size="medium" fullWidth>
                <Controller
                  control={form.control}
                  name="contact"
                  render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                    <TextField
                      name={name}
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={'Contact'}
                      disabled
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box display={'flex'} width={'100%'} gap={4}>
              <FormControl size="medium" fullWidth>
                <Controller
                  control={form.control}
                  name="city"
                  render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                    <TextField
                      name={name}
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={'City'}
                      disabled
                    />
                  )}
                />
              </FormControl>
              <FormControl size="medium" fullWidth>
                <Controller
                  control={form.control}
                  name="email"
                  render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                    <TextField
                      name={name}
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={'Email'}
                      disabled
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box display={'flex'} width={'100%'} gap={4}>
              <Box display={'flex'} width={'100%'} gap={2}>
                <FormControl size="medium" fullWidth>
                  <Controller
                    name="state"
                    control={form.control}
                    render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                      <TextField
                        name={name}
                        helperText={error ? error.message : null}
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label={'State'}
                        disabled
                      />
                    )}
                  />
                </FormControl>
                <FormControl size="medium" fullWidth>
                  <Controller
                    name="zip"
                    control={form.control}
                    render={({ field: { value, onChange, name }, fieldState: { error }, formState }) => (
                      <TextField
                        name={name}
                        helperText={error ? error.message : null}
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label={'Zip'}
                        disabled
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <FormControl size="medium" fullWidth>
                <Controller
                  name="phone"
                  control={form.control}
                  render={({ field: { value, onChange, name }, fieldState: { error }, formState }) => (
                    <TextField
                      name={name}
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={'Phone'}
                      disabled
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box display={'flex'} width={'100%'} gap={4}>
              <Input
                control={form.control}
                isDisabled={!pagePermissions.canEdit || !miscJob}
                label="Ship To "
                name="shipToCustomerID"
              />
              <Input
                control={form.control}
                isDisabled={!pagePermissions.canEdit || !miscJob}
                label="Sold To"
                name="soldToCustomerID"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">STO Delivery Address and Contact Information</Typography>
          <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2}>
            <Box display={'flex'} width={'100%'} gap={4}>
              <Input
                control={form.control}
                isDisabled={!pagePermissions.canEdit || jobContactData?.value?.jobOnHold}
                label="Street"
                name="stoStreet"
              />
              <Input
                control={form.control}
                isDisabled={!pagePermissions.canEdit || jobContactData?.value?.jobOnHold}
                label="Contact"
                name="stoContact"
              />
            </Box>
            <Box display={'flex'} width={'100%'} gap={4}>
              <Input
                control={form.control}
                isDisabled={!pagePermissions.canEdit || jobContactData?.value?.jobOnHold}
                label="City"
                name="stoCity"
              />
              <FormControl fullWidth>
                <Controller
                  control={form.control}
                  name="stoEmail"
                  rules={{
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email',
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={field.value ?? ''}
                      fullWidth
                      label={'Email'}
                      disabled={!pagePermissions.canEdit || jobContactData?.value?.jobOnHold}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box display={'flex'} width={'100%'} gap={4}>
              <Box display={'flex'} width={'100%'} gap={2}>
                <Input
                  control={form.control}
                  isDisabled={!pagePermissions.canEdit || jobContactData?.value?.jobOnHold}
                  label="State"
                  name="stoState"
                />
                <FormControl size="medium" fullWidth>
                  <Controller
                    name="stoZip"
                    control={form.control}
                    rules={{ pattern: { value: /^\d{5}$/, message: 'Invalid zip code' } }}
                    render={({ field: { value, onChange, name }, fieldState: { error }, formState }) => (
                      <PatternFormat
                        type="text"
                        label="Zip"
                        format="#####"
                        mask={'_'}
                        error={!!error}
                        helperText={error ? error.message : null}
                        customInput={TextField}
                        value={value}
                        fullWidth
                        onChange={onChange}
                        name={name}
                        disabled={!pagePermissions.canEdit || jobContactData?.value?.jobOnHold}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <FormControl size="medium" fullWidth>
                <Controller
                  name="stoPhone"
                  control={form.control}
                  rules={{
                    pattern: { value: /\(\d{3}\)\s*\d{3}-\d{4}$/, message: 'Invalid Phone' },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <PatternFormat
                      {...field}
                      required={false}
                      type="tel"
                      label="Phone"
                      format="(###) ###-####"
                      error={!!error}
                      helperText={error ? error.message : null}
                      customInput={TextField}
                      value={field.value ?? ''}
                      fullWidth
                      disabled={!pagePermissions.canEdit || jobContactData?.value?.jobOnHold}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
