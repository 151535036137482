import { useEffect, useRef, useState, useCallback } from 'react';
import { useJob } from '../../../utils/masterData';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSnackBar } from '../../../context/snackBarContext';
import { convertToGMT } from '../../../utils/dateUtils';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import HandymanIcon from '@mui/icons-material/Handyman';

import {
  Column,
  DataGrid,
  Editing,
  HeaderFilter,
  Lookup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  MasterDetail,
  Button as DevextremeButton,
} from 'devextreme-react/data-grid';
import { Box, Button, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';
import GridSkeleton from '../../shared/gridSkeleton';
import CutsGrid from '../PTQuantities/cutsGrid';
import { useQuery } from '@tanstack/react-query';
import { CustomLookupDropdown } from '../../shared/dataGrid/customLookupDropdown';
import { DropDownOptions } from 'devextreme-react/lookup';

export default function StudRailsForm({ pagePermissions }) {
  const { jobId } = useParams();
  const { setSnackbar } = useSnackBar();
  const [hasEditData, setHasEditData] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState();
  const [loading, setLoading] = useState(true);
  const [loadData, setLoadData] = useState();
  const [loadStatusLookUp, setLoadStatusLookUp] = useState();
  const [selectedRowKey, setSelectedRowKey] = useState({ selectedRowKeys: [] });
  const loadsDataGridRef = useRef(null);

  const { data: jobDetail, isLoading: jobDetailLoading } = useJob({ jobId: jobId });
  const navigate = useNavigate();
  const { data: chairLookUpData } = useQuery({
    queryKey: ['StudRailChairParts'],
    queryFn: async () => (await axios.get('PT/Parts/StudRailChairParts', { loaderRequired: false })).data,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        // Make calls to API concurrently
        const promises = await Promise.all([
          axios.get(`jobs/${jobId}/loads?type=2`, { loaderRequired: false }),
          axios.get(`pt/loadstatus`, { loaderRequired: false }),
        ]);

        setLoadData(promises[0]?.data);
        setLoadStatusLookUp(promises[1]?.data);
        setLoading(false);
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Error getting Stud Rails Quantities data',
          severity: 'error',
        });
        setLoading(false);
      }
    })();
  }, [jobId, setSnackbar]);

  //* add row button handler
  function handleAddRowButton() {
    loadsDataGridRef.current.instance.addRow();
  }
  //* Delete Icon visible render method - Based on cuts.
  const deleteButtonVisible = useCallback(
    (rowProps) => {
      return !rowProps.row.data?.hasCuts && pagePermissions.canEditLoad;
    },
    [pagePermissions]
  );
  //* onSaving datagrid handler
  async function handleSaving(e) {
    let changes = e.changes;

    let payload = { loads: [], loadsToRemove: [] };
    let haveInsert = false;
    let allRecords = loadsDataGridRef.current.instance.getVisibleRows();

    changes.map((change) => {
      //* logic for remove
      if (change.type === 'remove') {
        payload.loadsToRemove.push({ loadId: change.key });
      } else if (change.type === 'insert') {
        payload.loads.push({
          ...change.data,
          deliveryDate: convertToGMT(new Date(change.data.deliveryDate)),
          submittalDate: convertToGMT(new Date(change.data.submittalDate)),
        });

        haveInsert = true;
      } else {
        //* If this is update, get other fields as well
        let row = allRecords.find((r) => r.data?.loadId === change.key);
        console.log('abc', row);
        if (row?.data) {
          payload.loads.push({
            ...row.data,
            deliveryDate: convertToGMT(new Date(row.data.deliveryDate)),
            submittalDate: convertToGMT(new Date(row.data.submittalDate)),
          });
        }
      }
      payload.loads.forEach((item) => {
        item.type = 2;
      });
      return payload;
    });

    axios
      .put(`jobs/${jobId}/loads?type=2`, payload, { loaderRequired: false })
      .then(async () => {
        setSnackbar({
          open: true,
          message: `Saved successfully`,
          severity: 'success',
        });
        if (haveInsert) await refresh();
      })
      .catch(async (error) => {
        console.log(error);
        setSnackbar({
          open: true,
          message: `Error occured while saving.`,
          severity: 'error',
        });
      });
  }

  //* refresh datagrid data on insert new row or delete of row
  async function refresh() {
    try {
      const loadResponse = await axios.get(`jobs/${jobId}/loads?type=2`, { loaderRequired: false });
      setLoadData(loadResponse?.data);
    } catch (error) {
      console.log(error);
    }
  }

  //* handle edit btn state
  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());
  }

  //* cancel/reset button handler
  function handleCancelButton() {
    loadsDataGridRef.current.instance.cancelEditData();
  }

  //* save button handler
  function handleSaveButton() {
    loadsDataGridRef.current.instance.saveEditData();
    setHasEditData(true);
  }

  //* row focus handler
  function handleRowFocus(e) {
    if (e.row.key) {
      setSelectedRowKey([e.row.key]);
      setSelectedLoad(e.row.data);
    }
  }

  //* adds default values for new row insert in loads grid
  function onInitNewRow(e) {
    e.data.drawingStatusId = 1;
  }

  //* removes the data grid toolbar header buttons
  function handleOnToolbarPreparing(e) {
    //* remove add save and reset
    e.toolbarOptions.items.splice(0, 3);
  }

  function handleCutImport() {
    if (!selectedLoad || selectedLoad.loadId === undefined || selectedLoad.loadId <= 0) {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
      return;
    }

    navigate(`${selectedLoad.description}/cut-import?loadId=${selectedLoad.loadId}&mode=import`, {
      state: { loadId: selectedLoad.loadId, jobName: jobDetail?.value.name },
    });
  }

  const renderCutsForm = useCallback(
    (props) => {
      return (
        <CutsGrid {...props} jobName={jobDetail?.value.name} pagePermissions={pagePermissions} refresh={refresh} />
      );
    },
    [jobDetail?.value.name, pagePermissions]
  );

  const renderChairLookupDropdown = useCallback((props) => {
    const contentRender = (data) => (
      <ListItem disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <ListItemText
          secondaryTypographyProps={{ style: { fontWeight: 800 } }}
          secondary={`${data.partNumber} - ${data.materialId}`}
        />
        <ListItemText secondary={`${data.matDescription}`} />
      </ListItem>
    );

    return (
      <CustomLookupDropdown
        searchEnabled
        searchExpr={['matDescription', 'partNumber', 'materialId']}
        displayExpr={'matDescription'}
        valueExpr="partId"
        itemRender={contentRender}
        {...props}
      >
        <DropDownOptions height={600} minHeight={300} position={'left'} minWidth={300} />
      </CustomLookupDropdown>
    );
  }, []);

  const handleChairsBtnClick = useCallback(() => {
    if (selectedLoad?.description && selectedLoad?.loadId) {
      navigate(
        `${selectedLoad.description}/Chairs?loadId=${selectedLoad.loadId}&jn=${encodeURIComponent(
          jobDetail?.value?.name
        )}`
      );
    } else {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
    }
  }, [jobDetail, navigate, selectedLoad, setSnackbar]);

  return (
    <Box marginBottom={12} width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingBottom: 2,
        }}
      >
        <Typography variant="h1">
          {jobDetailLoading ? (
            <Skeleton sx={{ width: '400px', height: '100%' }} />
          ) : (
            `${jobDetail?.value.name} (${jobId})`
          )}
        </Typography>
        {pagePermissions.canEdit && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button className="btn" onClick={handleAddRowButton} disabled={!pagePermissions.canEditLoad}>
              Create Load
            </Button>
            <Button className="btn" onClick={handleCutImport} disabled={!jobDetail?.value?.existingInSAP}>
              Import Cuts
            </Button>
          </Box>
        )}
      </Box>
      <Box height={'100%'} display={'flex'}>
        {loading ? (
          <GridSkeleton />
        ) : (
          <DataGrid
            ref={loadsDataGridRef}
            id="gridContainer"
            className="grid-container"
            dataSource={loadData}
            rowAlternationEnabled={true}
            focusedRowEnabled={true}
            keyExpr={'loadId'}
            columnResizingMode="nextColumn"
            showBorders
            wordWrapEnabled
            allowColumnReordering
            allowColumnResizing
            onSaving={handleSaving}
            onContentReady={handleOnContentReady}
            onInitNewRow={onInitNewRow}
            onToolbarPreparing={handleOnToolbarPreparing}
            onFocusedRowChanged={handleRowFocus}
            selectedRowKeys={Array.from(selectedRowKey)}
            onEditorPreparing={(e) => {
              if (e.row.isNewRow && e.dataField === 'deliveryDate') {
                e.editorOptions.readOnly = false;
              } else if (e.dataField === 'deliveryDate') {
                if (e.row.data?.productionStatusId !== 1) e.editorOptions.readOnly = true;
              }
            }}
          >
            <MasterDetail enabled component={renderCutsForm} />
            <SearchPanel visible={false} placeholder="Search..." />
            <HeaderFilter visible />
            <StateStoring enabled type="localStorage" storageKey="studsLoad-data-grid" />
            <Selection mode="single" />
            <Scrolling mode="standard" />
            <Editing
              mode="batch"
              selectTextOnEditStart={true}
              allowEditing={pagePermissions.canEditLoad}
              allowUpdating={pagePermissions.canEditLoad}
              allowDeleting={pagePermissions.canEditLoad}
            />
            <Column
              dataField={'description'}
              allowFiltering={false}
              caption={'Load Description'}
              allowSearch
              editorOptions={{ maxLength: 15 }}
            >
              <RequiredRule />
            </Column>
            <Column
              dataField={'submittalDate'}
              allowFiltering={false}
              caption={'Submittal Date'}
              dataType="date"
              format={'MM/dd/yyyy'}
            >
              <RequiredRule />
            </Column>
            <Column
              dataField={'deliveryDate'}
              allowFiltering={false}
              caption={'Delivery Date'}
              dataType="date"
              format={'MM/dd/yyyy'}
            >
              <RequiredRule />
            </Column>
            <Column
              allowFiltering={false}
              caption={'Chair'}
              dataField="chairPartId"
              editCellComponent={(props) => renderChairLookupDropdown(props)}
              width="500"
            >
              <RequiredRule />
              <Lookup dataSource={chairLookUpData} valueExpr={'partId'} displayExpr={'matDescription'} />
            </Column>
            <Column
              dataField={'totalRails'}
              allowFiltering={false}
              caption={'Total #Rails'}
              allowEditing={false}
              alignment="left"
            ></Column>
            <Column
              dataField={'totalStuds'}
              allowFiltering={false}
              caption={'Total Studs'}
              allowEditing={false}
              alignment="left"
            ></Column>
            <Column
              dataField={'totalLinealFootage'}
              allowFiltering={false}
              caption={'Total Linear Footage'}
              allowEditing={false}
              minWidth={200}
              alignment="left"
            ></Column>
            <Column dataField={'drawingStatusId'} allowFiltering={false} caption={'Drawing Status'} alignment="left">
              <RequiredRule />
              <Lookup dataSource={loadStatusLookUp} valueExpr="id" displayExpr="description" />
            </Column>
            <Column type="buttons">
              <DevextremeButton name="delete" visible={deleteButtonVisible} />
            </Column>
          </DataGrid>
        )}
      </Box>
      <ActionBar
        sx={{
          justifyContent: 'end',
          display: 'flex',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
        >
          <Button
            className="btn"
            startIcon={<HandymanIcon size={20} />}
            disabled={!selectedLoad?.loadId}
            onClick={handleChairsBtnClick}
          >
            Chairs
          </Button>
        </Box>
        <ActionSubmitButton2
          buttonText={'Save'}
          disabled={!hasEditData || !pagePermissions.canEdit}
          onClick={handleSaveButton}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          disabled={!hasEditData}
          clickHandler={handleCancelButton}
          width="3%"
        />
      </ActionBar>
    </Box>
  );
}
