import { Box, Button, Grid, ListItem, ListItemText } from '@mui/material';
import DataGrid, {
  Column,
  CustomRule,
  Editing,
  HeaderFilter,
  Lookup,
  RequiredRule,
  Scrolling,
} from 'devextreme-react/data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useRef, useState } from 'react';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import ActionBar from '../../shared/actionBar';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import GridSkeleton from '../../shared/gridSkeleton';
import { useGetActiveHardwareParts } from '../../../utils/masterData';
import { useSnackBar } from '../../../context/snackBarContext';
import { queryClient } from '../../../App';
import { CustomLookupDropdown } from '../../shared/dataGrid/customLookupDropdown';
import { DropDownOptions } from 'devextreme-react/lookup';

const installLookup = [
  { id: 'S', name: 'Shop Installed' },
  { id: 'F', name: 'Field Installed' },
];
export default function HardwaresForm({ pagePermissions }) {
  const accessoriesPicksheetRef = useRef();
  const [hasEditData, setHasEditData] = useState(false);

  const [searchParams] = useSearchParams();
  const loadId = searchParams.get('loadId');
  const cutId = searchParams.get('cutId');

  const { setSnackbar } = useSnackBar();

  const {
    data: accessoriesPicksheet,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['PT/Load/loadId/Cuts/cutId/Parts', loadId, cutId],
    queryFn: async () => (await axios.get(`PT/Load/${loadId}/Cuts/${cutId}/Parts`, { loaderRequired: false })).data,
  });

  const updateAccPicksheet = useMutation({
    mutationFn: (accPicksheet) => axios.put(`pt/load/${loadId}/cuts/${cutId}/parts`, accPicksheet),
    onSuccess: async () =>
      queryClient.invalidateQueries({ queryKey: ['PT/Load/loadId/Cuts/cutId/Parts', loadId, cutId] }),
  });

  const { data: activeHardwarePartLookup } = useGetActiveHardwareParts();

  const totalColumnRender = useCallback(({ data }) => {
    let value = 0;
    if (data?.onRelease && data?.additional) {
      value = data.onRelease + data.additional;
      return <span> {value} </span>;
    }
    if (data?.onRelease) {
      value = data.onRelease;
      return <span> {value} </span>;
    }
    if (data?.additional) {
      value = data.additional;
      return <span> {value} </span>;
    }
    return <span> {value} </span>;
  }, []);

  //* Handle batch edit process request
  const processBatchRequest = useCallback(
    async (accPicksheet, component) => {
      try {
        await updateAccPicksheet.mutateAsync(accPicksheet);
        setSnackbar({ open: true, message: 'Hardware parts saved successfully', severity: 'success' });
        component.cancelEditData();
      } catch (error) {
        setSnackbar({ open: true, message: 'Error saving hardware parts', severity: 'error' });
      }
    },
    [setSnackbar, updateAccPicksheet]
  );

  const onSaving = useCallback(
    (event) => {
      event.cancel = true;
      let payload = {
        cutAccessories: [],
        cutAccessoriesToRemove: [],
      };
      //* generate the payload
      if (event.changes.length) {
        let allRecords = accessoriesPicksheetRef.current.instance.getVisibleRows();
        event.changes.forEach((change) => {
          if (change.type === 'remove') payload.cutAccessoriesToRemove.push(change.key);
          else if (change.type === 'insert') {
            payload.cutAccessories.push(change.data);
          } else {
            // If this is update, get other fields as well
            let row = allRecords.find(
              (r) => r.data?.partId === change.key?.partId && r.data?.installType === change.key?.installType
            );
            if (row?.data) {
              payload.cutAccessories.push({
                ...row.data,
              });
            }
          }
        });
        //* handle async request
        event.promise = processBatchRequest(payload, event.component);
      }
    },
    [processBatchRequest]
  );

  //* disable update for installType and partId(SAP Materaial)
  const onEditorPreparing = useCallback((event) => {
    if (!event.row.isNewRow && (event.dataField === 'installType' || event.dataField === 'partId')) {
      event.editorOptions.readOnly = true;
      return;
    }

    if (event.dataField !== 'additional' && event.row.data?.isSysGenerated) {
      event.editorOptions.readOnly = true;
      return;
    }

    // look up data filter applied (only shows the data having filter applied to).
    if (event.dataField === 'installType' && event.filterValues?.length) {
      const filter = event.filterValues.filter((item) => item != null);
      event.editorOptions.dataSource = installLookup.filter((item) => filter.includes(item.id));
    }
  }, []);

  //* partId(SAP Material) & Install type needs to be unique validation
  const sapMaterialValidation = useCallback(
    (options) => {
      //* install type is not selected return valid
      if (!options.data?.installType) return true;

      //* get all data to validate
      let allRecords = accessoriesPicksheetRef.current.instance.getVisibleRows();
      if (allRecords?.length) {
        let uniquePartIds = new Set();
        for (const row of allRecords) {
          //* check if partNumeber in Set and current row value is being edited.
          if (uniquePartIds.has(`${row.data?.partId} - ${row.data?.installType}`) && options.value === row.data?.partId)
            return false;
          uniquePartIds.add(`${row.data?.partId} - ${row.data?.installType}`);
        }
      }

      return true;
    },
    [accessoriesPicksheetRef]
  );

  //* partId(SAP Material) & Install type needs to be unique validation
  const installTyleValidation = useCallback(
    (options) => {
      //* partId(sapMaterial) is not selected return valid
      if (!options.data?.partyId) return true;

      //* get all data to validate
      let allRecords = accessoriesPicksheetRef.current.instance.getVisibleRows();
      if (allRecords?.length) {
        let uniqueInstallType = new Set();
        for (const row of allRecords) {
          //* check if partNumeber in Set and current row value is being edited.
          if (
            uniqueInstallType.has(`${row.data?.partId} - ${row.data?.installType}`) &&
            options.value === row.data?.installType
          )
            return false;
          uniqueInstallType.add(`${row.data?.partId} - ${row.data?.installType}`);
        }
      }
      return true;
    },
    [accessoriesPicksheetRef]
  );

  //* function to change row color based on isSystemGenerated.
  function onRowPrepared(e) {
    if (e.data?.isSysGenerated) {
      e.rowElement.style.backgroundColor = '#F0F0F0';
    }
  }

  //* render material description column values.
  function sapMaterialRender({ data: rowProps }) {
    let value = '';
    if (rowProps.data.partId) {
      value = activeHardwarePartLookup?.find((activePart) => activePart.partId === rowProps.data.partId)?.materialId;
    }
    return (
      <Box title={value} sx={{ background: 'inherit', border: 'none', outline: 'none' }}>
        {value}
      </Box>
    );
  }
  const editCellComponentPartIdColumn = useCallback(
    (props) => {
      const contentRender = (data) => (
        <ListItem disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <ListItemText
            secondaryTypographyProps={{ style: { fontWeight: 800 } }}
            secondary={`${data.partNumber} - ${data.materialId}`}
          />
          <ListItemText secondary={`${data.matDescription}`} />
        </ListItem>
      );

      return (
        <CustomLookupDropdown
          searchEnabled
          searchExpr={['matDescription', 'partNumber', 'materialId']}
          displayExpr={'matDescription'}
          valueExpr="partId"
          itemRender={contentRender}
          {...props}
        >
          <DropDownOptions height={600} minHeight={300} position={'left'} minWidth={300} />
        </CustomLookupDropdown>
      );
    },
    [accessoriesPicksheetRef] // grid instance
  );

  function validateTotalQuantity(options) {
    const { data } = options;
    return (data?.onRelease || 0) + (data?.additional || 0) >= 0;
  }

  if (isLoading) return <GridSkeleton />;

  if (error || accessoriesPicksheet?.errors?.length) return <p>Error gettting the data</p>;

  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 6 }}>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Button
            disabled={!pagePermissions.canEdit}
            onClick={() => accessoriesPicksheetRef.current.instance.addRow()}
            className="btn btn-blue btn-icon"
            startIcon={<AddIcon />}
          >
            Add Hardware
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mb: 6 }}>
        <DataGrid
          ref={accessoriesPicksheetRef}
          dataSource={accessoriesPicksheet.value}
          // keyExpr={['partId', 'installType']}
          id="accPickSheet"
          height={440}
          onToolbarPreparing={(e) => e.toolbarOptions.items.splice(0)}
          onContentReady={(e) => setHasEditData(e.component.hasEditData())}
          onSaving={onSaving}
          onEditorPreparing={onEditorPreparing}
          onRowPrepared={onRowPrepared}
        >
          <Editing
            allowAdding={pagePermissions.canEdit}
            allowDeleting={pagePermissions.canEdit}
            allowUpdating={pagePermissions.canEdit}
            allowEditing={pagePermissions.canEdit}
            mode="batch"
          />
          <HeaderFilter visible />
          <Scrolling mode="standard" />

          <Column
            dataField="partId"
            caption="Material Description"
            dataType="string"
            allowFiltering={false}
            editCellComponent={editCellComponentPartIdColumn}
            width={400}
          >
            <Lookup dataSource={activeHardwarePartLookup} valueExpr={'partId'} displayExpr={'matDescription'} />
            <RequiredRule />
            <CustomRule
              validationCallback={sapMaterialValidation}
              message="Duplicate SAP Material and Install Type found"
            />
          </Column>
          <Column
            dataField="materialId"
            dataType="string"
            caption="SAP Material"
            allowFiltering={false}
            allowEditing={false}
            cellComponent={sapMaterialRender}
          />
          <Column dataField="onRelease" dataType="number" caption="On Release" allowFiltering={false}>
            <RequiredRule />
            <CustomRule
              message="Calculated total value cannot be negative."
              validationCallback={validateTotalQuantity}
            />
          </Column>
          <Column
            dataField="additional"
            dataType="number"
            editorOptions={{ showSpinButtons: true, step: 50 }}
            caption="+/-"
            allowFiltering={false}
          >
            <CustomRule
              message="Calculated total value cannot be negative."
              validationCallback={validateTotalQuantity}
            />
          </Column>
          <Column
            caption="Total"
            dataType="number"
            cellRender={totalColumnRender}
            allowEditing={false}
            allowFiltering={false}
          />
          <Column
            dataField="installType"
            caption="Install Type"
            dataType="string"
            allowFiltering
            defaultFilterValues={['F']}
          >
            <Lookup dataSource={installLookup} valueExpr={'id'} displayExpr={'name'} />
            <CustomRule
              validationCallback={installTyleValidation}
              message="Duplicate SAP Material and Install Type found"
            />
            <RequiredRule />
          </Column>
        </DataGrid>
      </Grid>
      <ActionBar sx={{ justifyContent: 'end', display: 'flex', gap: '10px' }}>
        <ActionSubmitButton2
          buttonText={'Save'}
          onClick={() => accessoriesPicksheetRef.current.instance.saveEditData()}
          disabled={!hasEditData || !pagePermissions.canEdit}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          disabled={!hasEditData}
          clickHandler={() => accessoriesPicksheetRef.current.instance.cancelEditData()}
        />
      </ActionBar>
    </Grid>
  );
}
