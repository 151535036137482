import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useSnackBar } from '../../../context/snackBarContext';
import {
  Column,
  Editing,
  DataGrid,
  RequiredRule,
  Scrolling,
  Lookup,
  Selection,
  HeaderFilter,
  CustomRule,
} from 'devextreme-react/data-grid';
import { Grid, Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useGetKitPart, useGetActiveHardwareParts, usePTLocation } from '../../../utils/masterData';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import IconButton from '@mui/material/IconButton';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import GridSkeleton from '../../shared/gridSkeleton';
import { CustomLookupDropdown } from '../../shared/dataGrid/customLookupDropdown';
import { DropDownOptions } from 'devextreme-react/lookup';

export default function KitPartsForm(props) {
  const kitPartDataGridRef = useRef(null);
  const { setSnackbar } = useSnackBar();
  const [hasEditData, setHasEditData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { data: activeHardwarePartLookup } = useGetActiveHardwareParts();
  const { data: kitPartData, refetch: refetchKitPartData } = useGetKitPart({ kitId: props.data.kitId });

  //* removes the data grid toolbar header buttons
  function handleOnToolbarPreparing(e) {
    //* remove add, save and reset
    e.toolbarOptions.items.splice(0);
  }
  //* handle edit btn state
  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());
    return hasEditData;
  }

  //* add row button handler
  function handleAddRowButton() {
    kitPartDataGridRef.current.instance.addRow();
  }

  //* on save data grid handler
  async function handleSaving(e) {
    // default save behavior canceled
    e.cancel = true;
    let payload = {};
    let isDelete = false;
    let allRecords = kitPartDataGridRef.current.instance.getVisibleRows();

    e.changes.forEach((change) => {
      if (change.type === 'insert') {
        payload = change.data;
        payload.kitId = props.data.kitId;
      } else if (change.type === 'remove') {
        payload.partId = change.key;
        isDelete = true;
      } else {
        // If this is update, get other fields as well
        let row = allRecords.find((r) => r.data?.partId === change.key);
        if (row?.data) {
          payload = { ...row.data, kitId: props.data.kitId };
        }
      }
    });

    //grid default save behavior enabled back
    e.cancel = false;

    try {
      if (isDelete) {
        await axios.delete(`PT/Kits/${props.data.kitId}/Parts?partId=${payload.partId}`).then(async () => {
          setSnackbar({
            open: true,
            message: `Deleted successfully`,
            severity: 'success',
          });
        });
      } else {
        if (payload.kitId) {
          await axios.put(`PT/Kits/${props.data.kitId}/Parts`, payload, { loaderRequired: false }).then(async () => {
            setSnackbar({
              open: true,
              message: `Saved successfully`,
              severity: 'success',
            });
          });
        }
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error occured while updating Kit part.`,
        severity: 'error',
      });
    }
  }

  const handleInitNewRow = (e) => {
    // Set the default values for the new row
    e.data.multiplier = 1;
    if ((props.data.anchorType === 'DC') | (props.data.anchorType === 'DE')) e.data.installType = 'S';
    else if ((props.data.anchorType === 'LC') | (props.data.anchorType === 'LE')) e.data.installType = 'F';
  };

  function validateNumberRange(options) {
    //* check if value is between 0 to 10 return true
    return !(options?.value < 0 || options?.value > 10);
  }

  const updateKitPart = useCallback(
    (newData, value, currentRowData) => {
      let allRecords = kitPartDataGridRef.current.instance.getVisibleRows();

      // chcek partId already exist into grid
      let checkAlreadyExists = allRecords.find((r) => r.data?.partId === value);
      if (checkAlreadyExists?.data) {
        newData.partId = '';
        setSnackbar({
          open: true,
          message: `Duplicate part Id not allowed into system.`,
          severity: 'error',
        });
      } else {
        //update the readonly fields on dropdown changes
        let partData = activeHardwarePartLookup?.find((item) => item.partId === value);
        newData.sapMaterial = partData.materialId;
        newData.description = partData.matDescription;
        newData.uom = partData.matUOM;
        newData.partId = value;
        newData.manufactureId = partData.manufactureId;
      }
    },
    [activeHardwarePartLookup, setSnackbar]
  );
  const descriptionRender = useCallback(
    (eventProps) => {
      //* update description field on each row render.
      let partData = activeHardwarePartLookup?.find((item) => item.partId === eventProps.data.partId);
      const partDescription = partData?.matDescription;
      return <span>{partDescription}</span>;
    },
    [activeHardwarePartLookup]
  );

  const uomRender = useCallback(
    (eventProps) => {
      //* update UOM field on each row render.
      let partData = activeHardwarePartLookup?.find((item) => item.partId === eventProps.data.partId);
      const partUOM = partData?.matUOM;
      return <span>{partUOM}</span>;
    },
    [activeHardwarePartLookup]
  );

  const materialIdRender = useCallback(
    (eventProps) => {
      //* update Material field on each row render.
      let partData = activeHardwarePartLookup?.find((item) => item.partId === eventProps.data.partId);
      const partMaterialId = partData?.materialId;
      return <span>{partMaterialId}</span>;
    },
    [activeHardwarePartLookup]
  );
  const manufactureIdRender = useCallback(
    (eventProps) => {
      //* update Material field on each row render.
      let partData = activeHardwarePartLookup?.find((item) => item.partId === eventProps.data.partId);
      const manufactureId = partData?.manufactureId;
      return <span>{manufactureId}</span>;
    },
    [activeHardwarePartLookup]
  );
  const handleSwapButtonClick = (data) => {
    setSelectedRow(data);
    setOpenDialog(true);
  };

  const renderPartNumberLookupDropdown = useCallback((props) => {
    const contentRender = (data) => (
      <ListItem disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <ListItemText
          secondaryTypographyProps={{ style: { fontWeight: 800 } }}
          secondary={`${data.partNumber} - ${data.materialId}`}
        />
        <ListItemText secondary={`${data.matDescription}`} />
      </ListItem>
    );

    return (
      <CustomLookupDropdown
        searchEnabled
        searchExpr={['matDescription', 'partNumber', 'materialId']}
        displayExpr={'partNumber'}
        valueExpr="partId"
        itemRender={contentRender}
        {...props}
      >
        <DropDownOptions height={600} minHeight={300} position={'left'} minWidth={300} />
      </CustomLookupDropdown>
    );
  }, []);

  const substituteRender = useCallback(
    ({ data }) => (
      <IconButton
        onClick={() => handleSwapButtonClick(data)}
        sx={{
          color: data.hasSubstitutes && '#03a9f4',
        }}
      >
        <SwapCallsIcon />
      </IconButton>
    ),
    []
  );

  return (
    <Grid
      item
      xs={12}
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h1">Kit Parts</Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={handleAddRowButton} className="btn btn-blue btn-icon" startIcon={<AddIcon />}>
          Add Part
        </Button>
      </Box>

      <Grid item xs={12} marginBottom={5}>
        <DataGrid
          ref={kitPartDataGridRef}
          id="gridContainer"
          dataSource={kitPartData}
          keyExpr={'partId'}
          showBorders={true}
          rowAlternationEnabled={true}
          onSaving={handleSaving}
          onToolbarPreparing={handleOnToolbarPreparing}
          onContentReady={handleOnContentReady}
          onInitNewRow={handleInitNewRow}
          allowColumnResizing={true}
          noDataText="No parts on this kit"
          onEditorPreparing={(e) => {
            if (e.dataField === 'partId') {
              if (!e.row.isNewRow) e.editorOptions.readOnly = true;
            }
          }}
        >
          <HeaderFilter visible />
          <Selection mode="single" />
          <Editing allowAdding allowUpdating mode="row" allowDeleting />
          <Scrolling mode="infinite" />
          <Column width={90} cellRender={substituteRender} />

          <Column
            dataField="partId"
            caption="Part ID"
            allowSearch
            allowFiltering={false}
            setCellValue={updateKitPart}
            allowEditing={true}
            editCellComponent={(props) => renderPartNumberLookupDropdown(props)}
          >
            <RequiredRule />
            <Lookup dataSource={activeHardwarePartLookup} valueExpr={'partId'} displayExpr={'partNumber'} />
          </Column>
          <Column
            dataField="manufactureId"
            caption="Manufacture ID"
            allowEditing={false}
            allowSearch
            allowFiltering={false}
            cellRender={manufactureIdRender}
          />
          <Column
            dataField="sapMaterial"
            caption="SAP Material"
            allowEditing={false}
            allowSearch
            allowFiltering={false}
            cellRender={materialIdRender}
          />
          <Column
            dataField="description"
            caption="Description"
            allowEditing={false}
            allowSearch
            allowFiltering={false}
            cellRender={descriptionRender}
          ></Column>

          <Column
            dataField="uom"
            caption="UOM"
            allowEditing={false}
            allowSearch
            allowFiltering={false}
            cellRender={uomRender}
          ></Column>
          <Column dataField="multiplier" caption="Multiplier" allowSearch allowFiltering={false}>
            <RequiredRule />
            <CustomRule message="Please enter a number between 0 and 10." validationCallback={validateNumberRange} />
          </Column>
          <Column dataField="installType" caption="Install Type" allowFiltering={false}>
            <Lookup dataSource={installLookup} valueExpr={'id'} displayExpr={'name'} />s
            <RequiredRule />
          </Column>
        </DataGrid>
        {openDialog && (
          <KitsSubstitueModal
            kitData={{ ...selectedRow, kitName: props?.data?.kitName }}
            setOpenDialog={setOpenDialog}
            openDialog={openDialog}
            refetchData={refetchKitPartData}
          />
        )}
      </Grid>
    </Grid>
  );
}

const installLookup = [
  { id: 'S', name: 'SHOP' },
  { id: 'F', name: 'FIELD' },
];

function KitsSubstitueModal({ kitData, setOpenDialog, openDialog, refetchData }) {
  const kitPartSubstitutionDataGridRef = useRef(null);
  const [partSubstitutionData, setPartSubstitutionData] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: activeHardwarePartLookup } = useGetActiveHardwareParts();
  const { data: PTLocations, isLoading: PTLocationsLoading } = usePTLocation();
  const [hasEditData, setHasEditData] = useState(false);

  const { setSnackbar } = useSnackBar();

  useEffect(() => {
    (async () => {
      try {
        const { kitId, partId } = kitData;
        setIsLoading(true);
        const response = await axios.get(`pt/Kits/${kitId}/Parts/${partId}/substitute`);
        setPartSubstitutionData(response.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [kitData]);

  const handleCancelButton = () => {
    setOpenDialog(false);
  };

  async function processSubstitutionSaveRequest(payload, component) {
    try {
      setIsSubmiting(true);
      const { kitId, partId } = kitData;
      await axios.put(`pt/Kits/${kitId}/Parts/${partId}/substitute`, payload, { loaderRequired: false });
      setOpenDialog(false);
      setHasEditData(true);
      setSnackbar({ open: true, message: 'Saved successfully.', severity: 'success' });
      refetchData();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error saving.', severity: 'error' });
    } finally {
      setIsSubmiting(false);
      component.cancelEditData();
    }
  }

  function getPartNumber(partId) {
    let partData = activeHardwarePartLookup?.find((item) => item.partId === partId);
    return partData?.partNumber;
  }

  const handleOnToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.splice(0);
  }, []);

  function handleSubstituteSave() {
    kitPartSubstitutionDataGridRef.current.instance.saveEditData();
    setHasEditData(true);
  }

  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());
  }

  async function handleSaving(e) {
    // default save behavior canceled
    e.cancel = true;
    let payload = { kitPartSubstitutes: [] };
    let allRecords = kitPartSubstitutionDataGridRef.current.instance.getVisibleRows();
    let data = allRecords.map((r) => r.data);
    payload.kitPartSubstitutes = data;

    if (e.changes.length) {
      e.changes.forEach((item) => {
        if (item.type === 'remove') {
          payload.kitPartSubstitutes = payload.kitPartSubstitutes.filter((kit) => kit.locationId != item.key);
        }
      });

      e.promise = processSubstitutionSaveRequest(payload, e.component);
    }
  }

  // Location validation function
  function validateLocations(options) {
    const allRecords = kitPartSubstitutionDataGridRef.current.instance.getVisibleRows();
    const currentLocationIds = allRecords.map((row) => row.data.locationId);
    return allRecords.length ? currentLocationIds.filter((id) => id === options.value).length <= 1 : true;
  }

  const editCellComponentPartIdColumn = useCallback((props) => {
    const contentRenderPartId = (data) => (
      <ListItem disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <ListItemText
          primary={`${data.partNumber}`} 
          secondary={`${data.partNumber} - ${data.materialId}`}
          secondaryTypographyProps={{ style: { fontStyle: 'italic' } }} 
        />
        <ListItemText secondary={`${data.matDescription}`}  primary={`${data.matDescription}`} />
      </ListItem>
    );

    return (
      <CustomLookupDropdown
        searchEnabled
        searchExpr={['matDescription', 'partNumber']}
        displayExpr={'partNumber'}
        valueExpr="partId"
        itemRender={contentRenderPartId}
        {...props}
      >
        <DropDownOptions height={600} minHeight={300} position={'top'} minWidth={350} />
      </CustomLookupDropdown>
    );
  }, []);

  const sapMaterialRender = useCallback(
    ({ data: rowProps }) => {
      let value = '';
      if (rowProps.data.substPartId) {
        value = activeHardwarePartLookup?.find(
          (activePart) => activePart.partId === rowProps.data.substPartId
        )?.materialId;
      }

      return (
        <Box title={value} sx={{ background: 'inherit', border: 'none', outline: 'none' }}>
          {value}
        </Box>
      );
    },
    [activeHardwarePartLookup]
  );

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth
      open={openDialog}
      disableEnforceFocus
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setOpenDialog(false);
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant="h1">Configure Substitution by Location</Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 800, height: '100%', padding: 4 }}>
        {isLoading || PTLocationsLoading ? (
          <GridSkeleton />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <strong>Kit:</strong>&nbsp;&nbsp;
              {kitData?.kitName}
            </Grid>
            <Grid item xs={6}>
              <strong>Part:</strong> &nbsp;{getPartNumber(kitData?.partId)}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button
                className="btn btn-blue btn-icon"
                onClick={() => kitPartSubstitutionDataGridRef.current.instance.addRow()}
                startIcon={<AddIcon />}
                sx={{ mb: 2 }}
              >
                Add Substitute
              </Button>

              <DataGrid
                dataSource={partSubstitutionData}
                height={240}
                showBorders={true}
                keyExpr={'locationId'}
                id="substitutionGridContainer"
                ref={kitPartSubstitutionDataGridRef}
                onContentReady={handleOnContentReady}
                onToolbarPreparing={handleOnToolbarPreparing}
                onSaving={handleSaving}
                noDataText=" No Substitutes Data"
              >
                <Editing allowAdding allowUpdating allowDeleting mode="batch" />
                <Column dataField="locationId" caption="Location" alignment="left">
                  <RequiredRule />
                  <Lookup dataSource={PTLocations} valueExpr="locationID" displayExpr="locationName" />
                  <CustomRule validationCallback={validateLocations} message="Duplicate Locations not allowed" />
                </Column>
                <Column
                  dataField="substPartId"
                  caption="Part"
                  allowFiltering={false}
                  allowEditing={true}
                  alignment="left"
                  editCellComponent={editCellComponentPartIdColumn} //search not working here.
                >
                  <RequiredRule />
                  <Lookup dataSource={activeHardwarePartLookup} valueExpr="partId" displayExpr="partNumber" />
                </Column>
                <Column
                  dataType="string"
                  caption="SAP Material"
                  allowFiltering={false}
                  allowEditing={false}
                  cellComponent={sapMaterialRender}
                />
                <Column dataField="multiplier" caption="Multiplier" dataType="number" width={100} alignment="left">
                  <RequiredRule />
                </Column>
              </DataGrid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <ActionSubmitButton2
          loading={isSubmiting}
          buttonText={'Save'}
          onClick={handleSubstituteSave}
          disabled={!hasEditData || isSubmiting}
        />
        <ActionCancelButton disabled={isSubmiting} buttonText={'cancel'} clickHandler={handleCancelButton} />
      </DialogActions>
    </Dialog>
  );
}
